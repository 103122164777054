<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <b-button v-b-toggle.collapse-filter variant="primary" class="mt-3"
          >Filtry</b-button
        >
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <b-collapse visible id="collapse-filter">
        <div class="row mb-3">
          <div class="col-lg-2">
            <b-form-group label="Nazwa" label-for="name">
              <b-form-input
                id="name"
                placeholder="Podaj nazwę"
                v-model="name"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-lg-2">
            <b-form-group label="Kontakt" label-for="contactMetadata">
              <b-form-input
                id="contactMetadata"
                placeholder="Kontakt"
                v-model="contactMetadata"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-lg-3">
            <b-form-group label="Matryca" label-for="matrixFilter">
              <b-form-select
                id="matrix"
                v-model="matrixFilter"
                multiple
                :options="matrixList"
              ></b-form-select>
            </b-form-group>
          </div>
<!--          <div class="col-lg-3">-->
<!--            <div class="row">-->
<!--              <div class="col-lg-6">-->
<!--                <b-form-group label="Wielkość OD" label-for="sizeFrom">-->
<!--                  <b-form-input id="sizeFrom" v-model="sizeFrom"></b-form-input>-->
<!--                </b-form-group>-->
<!--              </div>-->
<!--              <div class="col-lg-6">-->
<!--                <b-form-group label="Wielkość DO" label-for="sizeTo">-->
<!--                  <b-form-input id="sizeTo" v-model="sizeTo"></b-form-input>-->
<!--                </b-form-group>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="row mb-3">
          <div class="col-lg-2">
            <b-form-group
              label="Data ostatniej modyfikacji Od"
              label-for="updatedAtFilterFrom"
            >
              <b-form-datepicker
                reset-button
                type="date"
                placeholder="dd.mm.rrrr"
                size="md"
                :start-weekday="1"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                }"
                id="updatedAtFilterFrom"
                v-model="updatedAtFilterFrom"
              >
              </b-form-datepicker>
            </b-form-group>
          </div>
          <div class="col-lg-2">
            <b-form-group
              label="Data ostatniej modyfikacji Do"
              label-for="updatedAtFilterTo"
            >
              <b-form-datepicker
                reset-button
                type="date"
                placeholder="dd.mm.rrrr"
                size="md"
                :start-weekday="1"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                }"
                id="updatedAtFilterTo"
                v-model="updatedAtFilterTo"
              >
              </b-form-datepicker>
            </b-form-group>
          </div>
          <div class="col-lg-2">
            <b-form-group label="Województwo" label-for="voivodeshipFilter">
              <b-form-select
                id="voivodeshipFilter"
                v-model="voivodeshipFilter"
                :options="voivodeshipList"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-lg-3">
            <ChooseAttendants v-model="attendantFilter"/>
          </div>
          <div class="col-lg-3">
            <b-form-group label="Status" label-for="statusFilter">
              <b-form-select
                id="statusFilter"
                v-model="statusFilter"
                multiple
                :options="labStatusList"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-1">
            <b-button 
              variant="success" 
              @click="filterData"
            >Filtruj
            </b-button>
          </div>
          <div class="col-lg-3">
            <b-button 
              variant="danger" 
              @click="resetFilters"
              class="ml-4"
            >Wyczyśc filtry</b-button>
          </div>
        </div>
      </b-collapse>
      <!--begin::Table-->
      <div class="table-responsive">
        <NoticeAddModal 
          :client="noticeClient" 
          :type="'standard'" 
          @closedNotice="() => this.noticeClient = {}"
        />
        <v-data-table
          dense
          :loading="isFiltering"
          v-model="selected"
          :headers="computedHeaders"
          :items="dataToShow"
          class="table custom-table table-head-custom table-vertical-center table-head-bg table-borderless"
          item-key="id"
          mobile-breakpoint="0"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageText: 'Wyników na stronę',
            pageText: '{0}-{1} na {2}',
            itemsPerPageOptions: [10, 30, 50, 100]
          }"
          :items-per-page="10"
          :custom-sort="customSort"
          @current-items="loadFirstNotes"
        >
          <template v-slot:header.modals="{ header }">
            <div style="width: 0; padding: 0 !important;"></div>
          </template>
          <template v-slot:header.actions="{ header }">
            <div class="text-right">
              {{ header.text }}
            </div>
          </template>

          <template v-slot:body="{ items, headers }">
            <tbody>
              <template v-for="(client, index) in items">
                <tr
                  :key="client.id"
                  :class="{
                    'bg-danger-light': isBlacklisted(client),
                    'bg-payment-late': checkIfAnyPaymentIsLate(client.id) && !isBlacklisted(client),
                    disabled: inlineEditingStatus
                  }"
                  :data-index="index"
                >
                  <td style="width: 0; padding: 0 !important;">
                    <ClientEditModal :client="client" />
                    <!-- <NoticeAddModal :client="client" :type="'laboratory'" /> -->
                  </td>
                  <td style="min-width: 200px;">
                    <a
                      @click="edit(client)"
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      <v-chip
                        v-if="client.lab_status !== null && client.lab_status.length > 0"
                        class="ma-1 p-2 h6"
                        :color="client.color"
                      >
                        {{ statusOneLetterPrefix(client.lab_status) }}
                      </v-chip>
                      {{ client.name }}
                    </a>
                  </td>
                  <td style="min-width: 80px;">
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      {{ client.lab_contactPersonFullName1 }}
                    </a>
                  </td>
                  <td style="min-width: 80px;">
                    <span class="text-dark-75 d-block font-size-lg">
                      {{ getPhones(client) }}
                    </span>
                  </td>
                  <td style="min-width: 80px;">
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                      :href="'mailto:' + client.lab_email1"
                    >
                      {{ getEmails(client) }}
                    </a>
                  </td>
                  <td style="min-width: 110px;">
                    <div style="width: 110px;">
                      <a
                        class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                      >
                        <b-form-datepicker
                          reset-button
                          type="date"
                          placeholder="dd.mm.rrrr"
                          size="sm"
                          :disabled="inlineEditingStatus"
                          :start-weekday="1"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric'
                          }"
                          @input="handleChangeNextContactDate($event, client)"
                          :value="getNextContactDateValueToDisplay(client)"
                          :class="{
                            'bg-secondary': dateIsCurrentlyEditedAndNotSaved(
                              client.id
                            ),
                            disabled: inlineEditingStatus
                          }"
                        >
                        </b-form-datepicker>
                      </a>
                    </div>
                  </td>
                  <td style="min-width: 275px;">
                    <a
                      @click="notice(client)"
                    >
                      <b-form-textarea
                        :value="newestNoteToDisplay(client.id)"
                        :class="{
                          'bg-secondary': noticeIsCurrentlyEditedAndNotSaved(
                            client.id
                          ),
                          disabled: inlineEditingStatus,
                          'custom-textarea': true,
                        }"
                        size="sm"
                      >
                      </b-form-textarea>
                    </a>
                  </td>
                  <td style="min-width: 80px;">
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      {{ getTranslatedTypes(client.lab_matrix) }}
                    </a>
                  </td>
<!--                  <td style="min-width: 60px;">-->
<!--                    <a-->
<!--                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"-->
<!--                    >-->
<!--                      {{ client.sales_size }}-->
<!--                    </a>-->
<!--                  </td>-->
                  <td style="min-width: 70px;">
                    <div style="width: 70px;">
                      <a
                        class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                      >
                        <span>{{
                          client.updatedAt !== null
                            ? new Date(
                                client.updatedAt.date
                              ).toLocaleDateString()
                            : "Brak"
                        }}</span>
                      </a>
                    </div>
                  </td>
                  <td style="min-width: 40px;">
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      {{ getTranslatedAttendant(client.lab_attendant) }}
                    </a>
                  </td>
                  <td class="text-right" style="min-width: 150px;">
                    <a
                      class="btn btn-light btn-xs mr-2"
                      @click="confirm(client)"
                      :class="{
                        'bg-secondary':
                          rowIsCurrentlyEditedAndNotSaved(client.id) ||
                          rowIsCurrentlyEditing(client.id),
                        disabled: inlineEditingStatus
                      }"
                    >
                      <i
                        class="fa p-0 text-primary"
                        :class="{
                          'spinner-border spinner-border-sm':
                            inlineEditingStatus &&
                            rowIsCurrentlyEditing(client.id),
                          'fa-check':
                            !inlineEditingStatus ||
                            (inlineEditingStatus &&
                              !rowIsCurrentlyEditing(client.id))
                        }"
                      ></i>
                    </a>

                    <a class="btn btn-light btn-xs mr-2" @click="order(client)">
                      <i class="fa fa-plus p-0 text-primary"></i>
                    </a>

                    <b-button
                      type="button"
                      @click="handleCollapseSO(client.id)"
                      class="btn btn-light btn-xs"
                    >
                      <template v-if="isSOListVisible(client.id)">
                        <i class="fa fa-angle-up p-0 text-primary"></i>
                      </template>
                      <template v-else>
                        <i class="fa fa-angle-down p-0 text-primary"></i>
                      </template>
                    </b-button>
                  </td>
                </tr>
                <tr :key="index + 10000">
                  <td class="bg-white" colspan="12">
                    <b-collapse
                      :visible="isSOListVisible(client.id)"
                      class="mt-2"
                    >
                      <table
                        class="table table-vertical-center table-noradius table-sm"
                      >
                        <thead>
                          <tr
                            class=""
                            style="border-radius: 0 !important;"
                          >
                            <th class="col-1">Numer zlecenia</th>
                            <th class="col-1">Numery próbek</th>
                            <th class="col-1">Data zlecenia</th>
                            <th class="col-1">Matryca</th>
                            <th class="col-1">Akcje</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template
                            v-for="so in getLabOrderForClient(client.id)"
                          >
                            <tr
                              :key="so.id"
                              class=" cursor-pointer text-hover-primary bg-secondary"
                              @click="handleCollapseSOI(so.id)"
                            >
                              <td class="bg-white">
                                <span v-if="so.status == 'draft'" style="color: red;">
                                  SZKIC &nbsp; 
                                </span>
                                {{ so.number }}
                              </td>
                              <td class="bg-white">{{ so.samples.map( (s, i) => {
                                let num = so.number.split('/');
                                num[0] += "-" + ( i + 1 );

                                return num.join('/');
                              } ).join(', ') }}</td>
                              <td class="bg-white">{{ so.createdAt }}</td>
                              <td class="bg-white">{{ so.matrix }}</td>
                              <td class="bg-white">
                                <a
                                    class="btn btn-light btn-xs mr-2"
                                    title="Edytuj wpis"
                                    @click="editLabOrder(so.id, client.id)"
                                >
                                  <i class="fa fa-edit p-0 text-primary"></i>
                                </a>

                                <a
                                    class="btn btn-light btn-xs mr-2"
                                    title="Usuń wpis"
                                    @click="removeLabOrder(so.id)"
                                    v-if="so.status == 'draft'"
                                >
                                  <i class="fa fa-trash p-0 text-primary"></i>
                                </a>      

                                <b-button-group v-if="so.status != 'draft'">
                                  <b-dropdown
                                    no-caret
                                    variant="outline"
                                    class="bg-light mr-2"
                                  >
                                    <template #button-content>
                                      <span class="m-0">
                                        <i
                                          class="fas fa-print p-0 text-primary"
                                        ></i>
                                      </span>
                                    </template>
                                    <b-dropdown-item>
                                      <a class="" @click="printLabOrder(so.id, 'broilery')">
                                        Broilery
                                      </a>
                                    </b-dropdown-item>
                                    <b-dropdown-item>
                                      <a class="" @click="printLabOrder(so.id, 'drob_nieobjety')">
                                        Drób nieobjęty programem
                                      </a>
                                    </b-dropdown-item>
                                    <b-dropdown-item>
                                      <a class="" @click="printLabOrder(so.id, 'indyk_hodowlany')">
                                        Indyk hodowlany
                                      </a>
                                    </b-dropdown-item>
                                    <b-dropdown-item>
                                      <a class="" @click="printLabOrder(so.id, 'indyk_rzezny')">
                                        Indyk rzeźny
                                      </a>
                                    </b-dropdown-item>
                                    <b-dropdown-item>
                                      <a class="" @click="printLabOrder(so.id, 'kury_nioski')">
                                        Kury nioski
                                      </a>
                                    </b-dropdown-item>
                                    <b-dropdown-item>
                                      <a class="" @click="printLabOrder(so.id, 'kury_reprodukcja')">
                                        Kury reprodukcja
                                      </a>
                                    </b-dropdown-item>
                                  </b-dropdown>
                                </b-button-group>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </b-collapse>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
      </div>

      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import {
  CLIENT_EDIT_FIELD,
  CLIENT_LIST,
  SET_ERROR
} from "@/core/services/store/client.module";
import { ORDER_LIST,CLEAR_ORDERS } from "@/core/services/store/order.module";
import { USER_LIST } from "@/core/services/store/user.module";
import { NOTICE_LIST } from "@/core/services/store/notice.module";
import {FERM_LIST,CLEAR_FERMS} from "@/core/services/store/ferm.module";
import NoticeAddModal from "@/view/content/modal/NoticeAddModal.vue";
import ClientEditModal from "@/view/content/modal/ClientEditModal.vue";
import Ripple from "vuetify/lib/directives/ripple";
import Vuetify from "vuetify";
import moment from "moment";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import currency from "currency.js";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ChooseAttendants from "@/view/content/form/ChooseAttendants";

import ApiService from "../../core/services/api.service";
import JwtService from "../../core/services/jwt.service";

export default {
  name: "order-panel-lab-table",
  components: {
    NoticeAddModal,
    ClientEditModal,
    ChooseAttendants
  },
  directives: {
    Ripple
  },
  vuetify: new Vuetify(),
  data() {
    return {
      pagination: {},
      noticeClient: {},
      isFiltering: false,
      sortBy: null,
      sortDesc: false,
      testLoading: false,
      editingNotices: [],
      editingNextContactDates: [],
      salesOrderItemsToShow: [],
      ordersForCollapse: [],
      collapseSO: [],
      collapseSOI: [],
      attendantFilter: [],
      voivodeshipList: [
        { value: "-select-", text: "Wybierz" },
        { value: "dolnośląskie", text: "dolnośląskie" },
        { value: "kujawsko-pomorskie", text: "kujawsko-pomorskie" },
        { value: "lubelskie", text: "lubelskie" },
        { value: "lubuskie", text: "lubuskie" },
        { value: "łódzkie", text: "łódzkie" },
        { value: "mazowieckie", text: "mazowieckie" },
        { value: "małopolskie", text: "małopolskie" },
        { value: "opolskie", text: "opolskie" },
        { value: "podkarpackie", text: "podkarpackie" },
        { value: "podlaskie", text: "podlaskie" },
        { value: "pomorskie", text: "pomorskie" },
        { value: "śląskie", text: "śląskie" },
        { value: "świętokrzyskie", text: "świętokrzyskie" },
        { value: "warmińsko-mazurskie", text: "warmińsko-mazurskie" },
        { value: "wielkopolskie", text: "wielkopolskie" },
        { value: "zachodniopomorskie", text: "zachodniopomorskie" }
      ],
      voivodeshipFilter: "-select-",
      statusFilter: [],
      matrixFilter: [],
      matrixList: [
        { value: "urzedowe", text: "Urzedowe" },
        { value: "weterynaria", text: "Weterynaria" },
        { value: "serologia", text: "Serologia" },
        { value: "pasza", text: "Pasza" },
        { value: "zywnosc", text: "Zywnosc" },
        { value: "woda", text: "Woda" },
        { value: "srodowisko", text: "Środowisko" }
      ],
      labStatusList: [
        { value: null, text: "Brak" },
        { value: "lab-active", text: "Laboratorium Aktywny" },
        { value: "lab-inactive", text: "Nieaktywny" },
        { value: "lab-progres", text: "Progres" }
      ],
      statusColor: {
        "lab-active": {
          bgColor: "primary",
          textColor: "write",
          prefix: "A"
        },
        "lab-inactive": {
          bgColor: "primary",
          textColor: "write",
          prefix: "N"
        },
        "lab-progres": {
          bgColor: "primary",
          textColor: "write",
          prefix: "P"
        }
      },
      name: "",
      contactMetadata: "",
      typeFilter: "",
      typeList: [
        { value: "", text: "Wybierz" },
        { value: "gesi", text: "Gęsi" },
        { value: "kaczki", text: "Kaczki" },
        { value: "indyki", text: "Indyki" },
        { value: "nioski reprodukcyjne", text: "Nioski reprodukcyjne" },
        { value: "nioski towarowe", text: "Nioski towarowe" },
        { value: "brojlery", text: "Brojlery" },
        { value: "golebie", text: "Gołębie" },
        { value: "norki", text: "Norki" },
        { value: "odchowka", text: "Odchówka" },
        { value: "kroliki", text: "Króliki" },
        { value: "inne", text: "Inne" }
      ],
      updatedAtFilterFrom: "",
      updatedAtFilterTo: "",
      sizeFrom: "",
      sizeTo: "",
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: "",
          value: "modals",
          sortable: false,
          style: "width: 0; padding: 0 !important;"
        },
        {
          text: "Nazwa firmy lub Imię i Nazwisko klienta",
          value: "name",
          // filter: value => {
          //   if (!this.name) return true;
          //   return value.toLowerCase().includes(this.name.toLowerCase());
          // },
          style: "min-width: 200px;"
        },
        {
          text: "Osoba do kontaktu",
          value: "contactMetadata",
          sortable: true,
          // filter: value => {
          //   if (!this.contactMetadata) return true;
          //   return value
          //     .toLowerCase()
          //     .includes(this.contactMetadata.toLowerCase());
          // },
          style: "min-width: 80px;"
        },
        {
          text: "Telefony",
          value: "phone",
          // filter: value => {
          //   if (!this.phone) return true;
          //   return value.toLowerCase().includes(this.phone.toLowerCase());
          // },
          style: "min-width: 80px;",
          sortable: false
        },
        {
          text: "Mail",
          value: "email",
          style: "min-width: 80px;",
          sortable: true
        },
        {
          text: "Data następnego kontaktu",
          value: "nextContactDate",
          style: "min-width: 110px;",
          sortable: true
          // sort: (a, b) => {
          //   // console.log("a ", a);
          //   // console.log("b ", b);
          //   if (
          //     (a === null || typeof a === "undefined") &&
          //     (b === null || typeof b === "undefined")
          //   ) {
          //     return 0;
          //   }
          //   if (
          //     (a === null || typeof a === "undefined") &&
          //     b !== null &&
          //     typeof b !== "undefined"
          //   ) {
          //     return 1;
          //   }
          //   if (
          //     (b === null || typeof b === "undefined") &&
          //     a !== null &&
          //     typeof a !== "undefined"
          //   ) {
          //     return -1;
          //   }
          //   if (
          //     a !== null &&
          //     typeof a !== "undefined" &&
          //     b !== null &&
          //     typeof b !== "undefined"
          //   ) {
          //     let dateA = null;
          //     let dateB = null;
          //
          //     if (moment.isMoment(a)) {
          //       dateA = a.format();
          //       dateA = new Date(dateA);
          //     } else {
          //       dateA = new Date(a.date);
          //     }
          //     if (moment.isMoment(b)) {
          //       dateB = b.format();
          //       dateB = new Date(dateB);
          //     } else {
          //       dateB = new Date(b.date);
          //     }
          //     return dateB - dateA;
          //   }
          //   return 0;
          // }
        },
        {
          text: "Notatki",
          value: "notices",
          style: "min-width: 275px;",
          sortable: false
        },
        {
          text: "Matryca",
          value: "lab_matrix",
          sortable: false,
          // filter: value => {
          //   if (!this.typeFilter) return true;
          //   return value === null
          //     ? true
          //     : value.includes(this.typeFilter.toLowerCase());
          // },
          // filter: matrixes => {
          //   //no statuses selected in filter -> show all
          //   if (this.matrixFilter.length === 0) return true;
          //   //statuses of client are null instead of object -> this is error -> hide this client as it is broken
          //   if (matrixes === null) return false;
          //   // below was used when all statuses from filter must match
          //   // return _.difference(this.statusFilter, statuses).length === 0;
          //   // below was used when all statuses from filter must match
          //   // return (
          //   //     this.statusFilter.length !==
          //   //     _.difference(this.statusFilter, statuses).length
          //   // );
          //   if (_.includes(this.matrixFilter, null)) {
          //     let filters = _.without(this.matrixFilter, null);
          //     if (filters.length === 0) {
          //       return matrixes.length === 0;
          //     } else {
          //       return (
          //         filters.length !== _.difference(filters, matrixes).length ||
          //         matrixes.length === 0
          //       );
          //     }
          //   }
          //   //below was used when any status from filter must watch
          //   return (
          //     this.matrixFilter.length !==
          //     _.difference(this.matrixFilter, matrixes).length
          //   );
          // },
          style: "min-width: 80px;"
        },
        // {
        //   text: "Wielkość",
        //   value: "sales_size",
        //   sortable: true,
        //   filter: value => {
        //     if (this.sizeFrom === "" && this.sizeTo === "") {
        //       return true;
        //     }
        //     if (
        //       this.sizeFrom !== "" &&
        //       (Number(value) < Number(this.sizeFrom) || value === "")
        //     ) {
        //       return false;
        //     }
        //     if (
        //       this.sizeTo !== "" &&
        //       (Number(value) > Number(this.sizeTo) || value === "")
        //     ) {
        //       return false;
        //     }
        //     return true;
        //   },
        //   style: "min-width: 60px;"
        // },
        {
          text: "Data ost. modyfikacji",
          value: "updatedAt",
          sortable: true,
          // filter: value => {
          //   if (value === null) return false;
          //   if (!this.updatedAtFilterFrom && !this.updatedAtFilterTo) {
          //     return true;
          //   }
          //   let jsDate = new Date(value.date);
          //   let momentDate = new moment(jsDate);
          //   if (this.updatedAtFilterFrom && !this.updatedAtFilterTo) {
          //     return momentDate.isSameOrAfter(
          //       new moment(this.updatedAtFilterFrom)
          //     );
          //   }
          //   if (!this.updatedAtFilterFrom && this.updatedAtFilterTo) {
          //     return momentDate.isSameOrBefore(
          //       new moment(this.updatedAtFilterTo)
          //     );
          //   }
          //   return (
          //     momentDate.isSameOrAfter(new moment(this.updatedAtFilterFrom)) &&
          //     momentDate.isSameOrBefore(new moment(this.updatedAtFilterTo))
          //   );
          // },
          style: "width: 50px;"
          // sort: (a, b) => {
          //   if (a === null && b === null) {
          //     return 0;
          //   }
          //   if (a === null && b !== null) {
          //     return 1;
          //   }
          //   if (a !== null && b === null) {
          //     return -1;
          //   }
          //   if (a !== null && b !== null) {
          //     return new Date(b.date) - new Date(a.date);
          //   }
          //   return 0;
          // }
        },
        {
          text: "Operator",
          value: "lab_attendant",
          sortable: false,
          // filter: value => {
          //   if (this.attendantFilter.length === 0) return true;
          //   if (value === null) return false;
          //   return _.includes(this.attendantFilter, value);
          // }
        },
        {
          text: "Adres",
          value: "voivodeship",
          // filter: value => {
          //   if (this.voivodeshipFilter === "-select-") return true;
          //   if (!this.voivodeshipFilter) return false;
          //   if (value === null) return false;
          //   return value.toLowerCase() === this.voivodeshipFilter.toLowerCase();
          // },
          sortable: false,
          align: " d-none"
        },
        {
          text: "Status",
          value: "lab_status",
          // filter: statuses => {
          //   //no statuses selected in filter -> show all
          //   if (this.statusFilter.length === 0) return true;
          //   //statuses of client are null instead of object -> this is error -> hide this client as it is broken
          //   if (statuses === null) return false;
          //   // below was used when all statuses from filter must match
          //   // return _.difference(this.statusFilter, statuses).length === 0;
          //   // below was used when all statuses from filter must match
          //   // return (
          //   //     this.statusFilter.length !==
          //   //     _.difference(this.statusFilter, statuses).length
          //   // );
          //   if (_.includes(this.statusFilter, null)) {
          //     let filters = _.without(this.statusFilter, null);
          //     if (filters.length === 0) {
          //       return statuses.length === 0;
          //     } else {
          //       return (
          //         filters.length !== _.difference(filters, statuses).length ||
          //         statuses.length === 0
          //       );
          //     }
          //   }
          //   //below was used when any status from filter must watch
          //   return (
          //     this.statusFilter.length !==
          //     _.difference(this.statusFilter, statuses).length
          //   );
          // },
          sortable: false,
          align: " d-none"
        },
        {
          text: "Akcje",
          value: "actions",
          sortable: false,
          style: "min-width: 150px; text-align: right;"
        }
      ],
      tableData: []
    };
  },
  mounted() {
    this.initMethod();
  },
  beforeUnmount() {
    this.$store.commit(CLEAR_FERMS);
    this.$store.commit('clearClients');
    this.$store.dispatch(USER_LIST);
    this.$store.commit(CLEAR_ORDERS);
    this.$store.dispatch(NOTICE_LIST);
    this.$store.commit("purgeLabOrders");
  },
  methods: {
    loadFirstNotes( visibleNotes ){
      let ids = visibleNotes.map( el => el.id );

      if ( ids.length === 0 )
        return;

      this.$store.dispatch('getLatestNoticeForItems', {ids});
    },
    removeLabOrder(id) {
      this.$store.dispatch("orderLabDelete", {
        id
      });

      this.$store.dispatch("orderLabList");
      this.$store.dispatch(CLIENT_LIST);
      
      this.$router.push("/labl/labOrders");
    },
    editLabOrder(id, clientId) {
      this.$router.push("/orderPanelLab/edit/" + id + "/" + clientId);
    },
    printLabOrder(id, template) {
      this.$store.dispatch("orderLabPrint", {
        id,
        template
      });
    },
    getLabOrderForClient(id) {
      return this.labOrders.filter( el => el.client.id === id );
    },
    transformPrice(value) {
      return currency(value, { fromCents: true });
    },
    customSort: function(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === "updatedAt") {
          const valA = a.updatedAt;
          const valB = b.updatedAt;
          if (valA === null && valB === null) {
            return 0;
          }
          if (valA === null && valB !== null) {
            return 1;
          }
          if (valA !== null && valB === null) {
            return -1;
          }
          if (valA !== null && valB !== null) {
            if (isDesc[0]) {
              return new Date(valB.date) - new Date(valA.date);
            }
            return new Date(valA.date) - new Date(valB.date);
          }
          return 0;
        } else if (index[0] === "sales_size") {
          const valA = parseFloat(a.sales_size);
          const valB = parseFloat(b.sales_size);
          if (isNaN(valA) && isNaN(valB)) {
            return 0;
          }
          if (isNaN(valA) && !isNaN(valB)) {
            return 1;
          }
          if (!isNaN(valA) && isNaN(valB)) {
            return -1;
          }
          if (!isNaN(valA) && !isNaN(valB)) {
            if (isDesc[0]) {
              return valB - valA;
            }
            return valA - valB;
          }
          return 0;
        } else if (index[0] === "nextContactDate") {
          // if (!isDesc[0]) {
          //   return new Date(b[index]) - new Date(a[index]);
          // } else {
          //   return new Date(a[index]) - new Date(b[index]);
          // }

          if (
            (a.nextContactDate === null ||
              typeof a.nextContactDate === "undefined") &&
            (b.nextContactDate === null ||
              typeof b.nextContactDate === "undefined")
          ) {
            return 0;
          }

          if (
            (a.nextContactDate === null ||
              typeof a.nextContactDate === "undefined") &&
            b.nextContactDate !== null &&
            typeof b.nextContactDate !== "undefined"
          ) {
            return 1;
          }

          if (
            (b.nextContactDate === null ||
              typeof b.nextContactDate === "undefined") &&
            a.nextContactDate !== null &&
            typeof a.nextContactDate !== "undefined"
          ) {
            return -1;
          }

          if (
            a.nextContactDate !== null &&
            typeof a.nextContactDate !== "undefined" &&
            b.nextContactDate !== null &&
            typeof b.nextContactDate !== "undefined"
          ) {
            let sortA = this.getNextContactDateValueToSort(a);
            let sortB = this.getNextContactDateValueToSort(b);
            let dateA = null;
            let dateB = null;
            dateA = sortA;
            dateB = sortB;
            if (isDesc[0]) {
              return dateB - dateA;
            }
            return dateA - dateB;
          }
          return 0;
        } else {
          if (
            typeof a[index] !== "undefined" &&
            typeof b[index] !== "undefined"
          ) {
            const valA = a[index];
            const valB = b[index];
            if (valA === null && valB === null) {
              return 0;
            }
            if (valA === null && valB !== null) {
              return 1;
            }
            if (valA !== null && valB === null) {
              return -1;
            }
            if (valA !== null && valB !== null) {
              if (isDesc[0]) {
                return a[index]
                  .toLowerCase()
                  .localeCompare(b[index].toLowerCase());
              }
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
            return 0;
          }
        }
      });
      return items;
    },
    initMethod() {
      this.$store.dispatch(FERM_LIST);
      this.$store.dispatch(CLIENT_LIST);
      this.$store.dispatch(USER_LIST);
      this.$store.dispatch(ORDER_LIST);
      this.$store.dispatch(NOTICE_LIST);
      this.$store.dispatch("orderLabList");
    },
    isSOListVisible(id) {
      return this.collapseSO.includes(id);
    },
    isSOIListVisible(id) {
      return this.collapseSOI.includes(id);
    },
    getOrderById(id) {
      return _.find(this.orders, o => {
        return o.id === id;
      });
    },
    getOrdersDataForUser(id) {
      return _.filter(this.orders, o => {
        return o.clientId === id;
      });
    },
    checkIfAnyPaymentIsLate(clientId) {
      dayjs.extend(customParseFormat);
      let orders = _.filter(this.orders, o => {
        return o.clientId === clientId;
      });
      let late = false;
      orders.forEach((order) => {
        if (!late && !order.paid && !dayjs(order.paymentDate, 'DD.MM.YYYY').isAfter()) {
          late = true;
        }
      });
      return late;
    },
    countOrdersDataForUser(id) {
      return _.filter(this.orders, o => {
        return o.clientId === id;
      }).length;
    },
    statusOneLetterPrefix(clientStatus) {
      let arr = [];
      for (const status of clientStatus) {
        arr.push(this.statusColor[status].prefix);
      }
      return _.join(arr, "/");
    },
    handleCollapseSO(id) {
      const exists = _.includes(this.collapseSO, id);
      if (!exists) {
        this.collapseSO.push(id);
      } else {
        const index = _.findIndex(this.collapseSO, id);
        let collapseSO = [...this.collapseSO];
        collapseSO.splice(index, 1);
        this.collapseSO = collapseSO;
      }
    },
    handleCollapseSOI(id) {
      const exists = _.includes(this.collapseSOI, id);
      if (!exists) {
        this.collapseSOI.push(id);
      } else {
        const index = _.findIndex(this.collapseSOI, id);
        let collapseSOI = [...this.collapseSOI];
        collapseSOI.splice(index, 1);
        this.collapseSOI = collapseSOI;
      }
    },
    getTranslatedTypes(types) {
      if (_.isNull(types) || _.size(types) === 0) {
        return "-";
      }
      return types.join(", ");
    },
    notice(item) {
      this.noticeClient = item;
      this.$bvModal.show("notice-add-modal-");
    },
    edit(item) {
      this.$bvModal.show("modal-client-edit-" + item.id);
    },
    async order(item) {
      // Create draft and go to the edit page for it
      // TODO: Before anything, we need to show a popup and ask for acceptance 
      console.log(item);
      Swal.fire({
        title: "Czy na pewno chcesz utworzyć nowe zlecenie?",
        text: `${item.name} ${item.street} ${item.houseNumber} ${item.postalCode} ${item.city} NIP: ${item.nip} `,
        icon: "warning",
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-primary",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie"
      }).then(async result => {
        if (result.isConfirmed) {
          if (JwtService.getToken()) {
          ApiService.setHeader();

          try {
            let res = await ApiService.post('labOrderDraft', {
              clientId: item.id
            });
            
            if ( res.data )
              this.$router.push("/orderPanelLab/edit/" + res.data.data.labOrder.id + "/" + item.id);
          
          } catch (e) {
            // console.error(e)
            Swal.fire('Błąd');
          }
        }
        }
      });
    },
    confirm(item) {
      const indexOfNotice = _.findIndex(this.editingNotices, function(o) {
        return o.id === item.id;
      });
      if (indexOfNotice !== -1) {
        const newValue1 = this.editingNotices[indexOfNotice].value;
        this.$store.dispatch(CLIENT_EDIT_FIELD, {
          id: item.id,
          name: "notices",
          value: newValue1
        });
        this.editingNotices.splice(indexOfNotice, 1);
      }
      const idxOfDate = _.findIndex(this.editingNextContactDates, function(o) {
        return o.id === item.id;
      });
      if (idxOfDate !== -1) {
        let newValue2 = this.editingNextContactDates[idxOfDate].value;
        if (newValue2 !== null) {
          newValue2 = new moment(newValue2).format("YYYY-MM-DD");
        }
        this.$store.dispatch(CLIENT_EDIT_FIELD, {
          id: item.id,
          name: "nextContactDate",
          value: newValue2
        });
        this.editingNextContactDates.splice(idxOfDate, 1);
      }
    },
    newestNoteToDisplay(client) {
      if ( !this.latestNotices )
        return "";

      let n = this.latestNotices.filter( el => el.clientId == client )

      if ( n.length > 0 )
        return n[0].content;

      return "";
    },
    getItemNoticeValueToDisplay(item) {
      const index = _.findIndex(this.editingNotices, function(o) {
        return o.id === item.id;
      });
      if (index !== -1) {
        return this.editingNotices[index].value;
      }
      return item.notices;
    },
    handleChangeNotices: _.debounce(function($event, item) {
      let oldValue = item.notices;
      let newValue = $event;
      if (newValue === "") {
        newValue = null;
      }
      const index = _.findIndex(this.editingNotices, function(o) {
        return o.id === item.id;
      });
      if (index === -1) {
        this.editingNotices.push({
          id: item.id,
          value: newValue
        });
      } else {
        if (oldValue === newValue) {
          this.editingNotices.splice(index, 1);
        } else {
          this.editingNotices.splice(index, 1, {
            id: item.id,
            value: newValue
          });
        }
      }
    }, 300),
    getNextContactDateValueToSort(item) {
      return this.getNextContactDate(item);
    },
    getNextContactDateValueToDisplay(item) {
      const index = _.findIndex(this.editingNextContactDates, function(o) {
        return o.id === item.id;
      });
      if (index !== -1) {
        if (this.editingNextContactDates[index].value === null) {
          return null;
        }
        if (this.editingNextContactDates[index].value.hasOwnProperty("date")) {
          return new Date(this.editingNextContactDates[index].value.date);
        } else {
          return new Date(this.editingNextContactDates[index].value);
        }
      }
      return this.getNextContactDate(item);
    },
    handleChangeNextContactDate: _.debounce(function($event, item) {
      let oldValue = this.getNextContactDate(item);
      let newValue = null;
      if ($event !== "") {
        newValue = new moment($event);
      }
      // console.log(newValue);
      // item.nextContactDate = newValue;
      const index = _.findIndex(this.editingNextContactDates, function(o) {
        return o.id === item.id;
      });
      if (index === -1) {
        this.editingNextContactDates.push({
          id: item.id,
          value: newValue,
          oldValue: oldValue
        });
      } else {
        let oldToCompare = null;
        if (this.editingNextContactDates[index].oldValue !== null) {
          oldToCompare = moment(
            this.editingNextContactDates[index].oldValue
          ).format("YYYY-MM-DD");
        }
        let newToCompare = null;
        if (newValue !== null) {
          newToCompare = moment(newValue).format("YYYY-MM-DD");
        }
        if (oldToCompare === newToCompare) {
          this.editingNextContactDates.splice(index, 1);
        } else {
          this.editingNextContactDates.splice(index, 1, {
            id: item.id,
            value: newValue,
            oldValue: this.editingNextContactDates[index].oldValue
          });
        }
      }
    }, 300),
    getNextContactDate(item) {
      // console.log(item);
      if (item.nextContactDate !== null) {
        if (item.nextContactDate.hasOwnProperty("date")) {
          return new Date(item.nextContactDate.date);
        } else {
          return new Date(item.nextContactDate);
        }
      }
      return null;
    },
    getTranslatedPaymentMethod(pm) {
      if (pm === "transfer") {
        return "Przelew";
      } else if (pm === "cash") {
        return "Gotówka";
      }
      return "Pobranie";
    },
    getSalesOrderItemsToShow(soId) {
      return this.getOrderById(soId).salesOrderItems;
    },
    noticeIsCurrentlyEditedAndNotSaved(id) {
      const index = _.findIndex(this.editingNotices, function(o) {
        return o.id === id;
      });
      return index !== -1;
    },
    dateIsCurrentlyEditedAndNotSaved(id) {
      const index = _.findIndex(this.editingNextContactDates, function(o) {
        return o.id === id;
      });
      return index !== -1;
    },
    rowIsCurrentlyEditing(id) {
      return id === this.inlineEditingStatusId;
    },
    rowIsCurrentlyEditedAndNotSaved(id) {
      return (
        this.noticeIsCurrentlyEditedAndNotSaved(id) ||
        this.dateIsCurrentlyEditedAndNotSaved(id)
      );
    },
    isBlacklisted(item) {
      return _.includes(item.lab_status, "blacklisted");
    },
    getPhones(item) {
      return item.lab_phone1 + " " + item.lab_phone2 + " " + item.lab_phone3;
    },
    getEmails(item) {
      return item.lab_email1 + " " + item.lab_email2 + " " + item.lab_email3;
    },
    getTranslatedAttendant(attId) {
      const a = _.find(this.attendantsList, { value: attId });
      if (!_.isUndefined(a)) return a.text;
      return "";
    },
    nameFilter(value) {
        if (!this.name) return true;
          return value.toLowerCase().includes(this.name.toLowerCase());
    },
    contactMetaDataFilter(value) {
        if (!this.contactMetadata) return true;
          return value
            .toLowerCase()
            .includes(this.contactMetadata.toLowerCase());
    },
    phoneFilter(value) {
        if (!this.phone) return true;
          return value.toLowerCase().includes(this.phone.toLowerCase());  
    },
    labMatrixFilter(matrixes) {
        if (this.matrixFilter.length === 0) return true;
          //statuses of client are null instead of object -> this is error -> hide this client as it is broken
        if (matrixes === null) return false;
        // below was used when all statuses from filter must match
        // return _.difference(this.statusFilter, statuses).length === 0;
        // below was used when all statuses from filter must match
        // return (
        //     this.statusFilter.length !==
        //     _.difference(this.statusFilter, statuses).length
        // );
        if (_.includes(this.matrixFilter, null)) {
          let filters = _.without(this.matrixFilter, null);
          if (filters.length === 0) {
            return matrixes.length === 0;
          } else {
            return (
              filters.length !== _.difference(filters, matrixes).length ||
              matrixes.length === 0
            );
          }
        }
        //below was used when any status from filter must watch
        return (
          this.matrixFilter.length !==
          _.difference(this.matrixFilter, matrixes).length
        );
    },
    updatedAtFilter(value) {
        if (value === null) return false;
          if (!this.updatedAtFilterFrom && !this.updatedAtFilterTo) {
            return true;
          }
          let jsDate = new Date(value.date);
          let momentDate = new moment(jsDate);
          if (this.updatedAtFilterFrom && !this.updatedAtFilterTo) {
            return momentDate.isSameOrAfter(
              new moment(this.updatedAtFilterFrom)
            );
          }
          if (!this.updatedAtFilterFrom && this.updatedAtFilterTo) {
            return momentDate.isSameOrBefore(
              new moment(this.updatedAtFilterTo)
            );
          }
          return (
            momentDate.isSameOrAfter(new moment(this.updatedAtFilterFrom)) &&
            momentDate.isSameOrBefore(new moment(this.updatedAtFilterTo))
          );
    },
    labAttedantFilter(value) {
        if (this.attendantFilter.length === 0) return true;
          if (value === null) return false;
          return _.includes(this.attendantFilter, value);
    },
    voivodshipFilter(value) {
        if (this.voivodeshipFilter === "-select-") return true;
          if (!this.voivodeshipFilter) return false;
          if (value === null) return false;
          return value.toLowerCase() === this.voivodeshipFilter.toLowerCase();
    },
    labStatusFilter(statuses) {
        //no statuses selected in filter -> show all
          if (this.statusFilter.length === 0) return true;
          //statuses of client are null instead of object -> this is error -> hide this client as it is broken
          if (statuses === null) return false;
          // below was used when all statuses from filter must match
          // return _.difference(this.statusFilter, statuses).length === 0;
          // below was used when all statuses from filter must match
          // return (
          //     this.statusFilter.length !==
          //     _.difference(this.statusFilter, statuses).length
          // );
          if (_.includes(this.statusFilter, null)) {
            let filters = _.without(this.statusFilter, null);
            if (filters.length === 0) {
              return statuses.length === 0;
            } else {
              return (
                filters.length !== _.difference(filters, statuses).length ||
                statuses.length === 0
              );
            }
          }
          //below was used when any status from filter must watch
          return (
            this.statusFilter.length !==
            _.difference(this.statusFilter, statuses).length
          );
    },
    resetFilters() {
      [
        'name', 'contactMetadata', 'phone', 'matrixFilter', 'updatedAtFilterFrom', 'updatedAtFilterTo', 'attendantFilter', 'voivodeshipFilter', 'statusFilter'
      ].forEach( filter => filter == 'voivodeshipFilter' ? '-select-' : this[filter] = '' );

      this.tableData = [ ...this.clients ]
    },
    filterData() {
      this.isFiltering = true;
      let filters = [
        'name', 'contactMetadata', 'phone', 'matrixFilter', 'updatedAtFilterFrom', 'updatedAtFilterTo', 'attendantFilter', 'voivodeshipFilter', 'statusFilter'
      ];

      /**
       * FilterVarName: FilterFunctionName
       * @type {Object}
       */
      let filterFunctions = {
        'name': 'nameFilter', 
        'contactMetadata': 'contactMetaDataFilter', 
        'phone': 'phoneFilter', 
        'lab_matrix': 'labMatrixFilter', 
        'updatedAt': 'updatedAtFilter', 
        'lab_attendant': 'labAttedantFilter', 
        'voivodship': 'voivodshipFilter', 
        'lab_status': 'labStatusFilter'
      };

      let filtersMapped = filters.map( prop => this[prop] );

      if ( filtersMapped[9] == '-select-' )
        filtersMapped[9] = '';

      filtersMapped = filtersMapped.map( el => !el || el.length == 0 ? false : el );

      if ( filtersMapped.some( el => el !== false ) )
      {
        this.tableData = [ ...this.clients.filter( el => {
          return !Object.keys(filterFunctions).map( prop => {
            return this[ filterFunctions[prop] ](el[prop]);
          }).some( el => el == false )
        }) ]; 
      }

      this.isFiltering = false;

      return [ ...this.clients ];
    }
  },
  watch: {
    clientErrors(newValue) {
      if (this.clientErrors) {
        Swal.fire("Błąd!", newValue, "error").then(() => {
          this.$store.dispatch(SET_ERROR, null);
          this.$router.go();
        });
      }
    },
    "clients": {
      immediate: true,
      handler() {
        this.tableData = [ ...this.clients ];
      }
    }
  },
  computed: {
    ...mapGetters([
      "notices",
      "clients",
      "users",
      "orders",
      "inlineEditingStatusId",
      "clientErrors",
      "labOrders",
      "latestNotices"
    ]),
    inlineEditingStatus() {
      return this.inlineEditingStatusId > 0;
    },
    dataToShow() {
      return [...this.tableData].sort( (a, b) => a.name.localeCompare(b.name, 'pl'));
      // const filteredClients = _.filter(this.clients, o => {
      //   return _.includes(o.lab_status, "laboratory");
      // });
      // return filteredClients;
    },
    computedHeaders() {
      return this.headers;
    },
    attendantsList() {
      let result = [
        {
          value: -1,
          text: "Brak"
        }
      ];
      this.users.forEach(user => {
        result.push({
          value: user.id,
          text: user.firstName + " " + user.lastName
        });
      });
      return result;
    }
  }
};
</script>
